import React from "react";

import "./style.scss";
import Helmet from "./helmet";
import Header from "./header";
import Midsection from "./midsection";
import Footer from "./footer";
import Navbar from "./navbar";

interface Props {
  children?: any;
}

const Layout = ({ children }: Props) => (
  <div>
    <Helmet />
    <Navbar />
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
