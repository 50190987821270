import React from "react";
import "./style.scss";
import { FaTwitter, FaGithub } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";

export default function Navbar() {
  const [isActive, setisActive] = React.useState(false);
  return (
    <StaticQuery
      query={graphql`
        query NavbarQuery {
          site {
            siteMetadata {
              gatsby
              bulma
              twitter
              medium
              github
            }
          }
        }
      `}
      render={(data) => (
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <h1 className="title has-text-primary">Viet Tran</h1>
            </a>

            <a
              onClick={() => {
                setisActive(!isActive);
              }}
              role="button"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div
            id="navbarBasicExample"
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
          >
            <div className="navbar-start">
              <a className="navbar-item" href="/">
                Home
              </a>

              <a
                className="navbar-item"
                href="https://registry.jsonresume.org/vttran918"
              >
                Resume
              </a>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <span className="icon is-medium">
                  <a href={data.site.siteMetadata.github}>
                    <FaGithub size="2em" />
                  </a>
                </span>
                &nbsp; &nbsp;
                <span className="icon is-medium">
                  <a href={data.site.siteMetadata.twitter}>
                    <FaTwitter size="2em" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </nav>
      )}
    />
  );
}
