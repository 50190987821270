import React from "react";
import { FaTwitter, FaGithub, FaMedium } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import "./style.scss";
import MadeWithBulma from "../images/made-with-bulma.png";
import GatsbyIcon from "../images/gatsby-icon.png";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            gatsby
            bulma
            twitter
            medium
            github
          }
        }
      }
    `}
    render={(data) => (
      <footer className="footer center has-background-light">
        <div className="content has-text-centered">
          <div className="level">
            <div className="level-item">
              <a href="https://bulma.io">
                <img
                  src={MadeWithBulma}
                  alt="Made with Bulma"
                  width="128"
                  height="24"
                />
              </a>
            </div>
            <div className="level-item">
              <figure className="image is-32x32">
                <a href="https://gatsbyjs.com">
                  <img src={GatsbyIcon} alt="Made with Gatsby" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
);

export default Footer;
